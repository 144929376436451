@import "@/assets/scss/custom"; @import "~bootstrap/scss/bootstrap";

.modal-enter-active,
.modal-leave-active {
  transition: opacity .15s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

#staticBackdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
