@import "@/assets/scss/custom"; @import "~bootstrap/scss/bootstrap";

button {
  @include media-breakpoint-down("sm") {
    --bs-btn-font-size: .9rem !important;
    --bs-btn-padding-y: .2rem !important;
    --bs-btn-padding-x: .4rem !important;
  }

  &.btn-link {
    font-size: inherit;
  }

  svg {
    display: inline-block;
    width: 25px;
    height: 25px;

    @include media-breakpoint-down("sm") {
      width: 15px;
      height: 15px;
    }

    &.link-icon {
      width: 15px;
      height: 15px;
      --bs-btn-padding-y: 0;
      --bs-btn-padding-x: 0;
    }
  }

  &.small {
    --bs-btn-font-size: .9rem !important;
    --bs-btn-padding-y: .2rem !important;
    --bs-btn-padding-x: .4rem !important;   

    svg {
      width: 15px;
      height: 15px;

      @include media-breakpoint-down("sm") {
        width: 15px;
        height: 15px;
      }

      &.link-icon {
        width: 15px;
        height: 15px;
      }
    }
  }
}

button:disabled {
    background-color: #888 !important;
    border-color: #888 !important;
  }
