@import "@/assets/scss/custom"; @import "~bootstrap/scss/bootstrap";
@import "custom";
@import "~bootstrap/scss/bootstrap";

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    min-height: -moz-available;
    min-height: stretch;
  }
  
  html {
    height: -webkit-fill-available;    
    min-height: -moz-available;
    min-height: stretch;
  }

  main {
    min-width: 100vw;
  }

  main, div#app {
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    height: -webkit-fill-available;
    height: -moz-available;
    height: stretch;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
  }