@import "@/assets/scss/custom"; @import "~bootstrap/scss/bootstrap";

 div#sidebar-nav {
  height: 100vh;
  width: 50vh;
  background-color: shade-color($green, 5%) !important;
  @include media-breakpoint-up(md) {
    width: 280px;
  }

 .nav-link {  
    border-left:$sidebar-nav-accent-size solid transparent !important;
    
    &:not(&.router-link-active):hover {
       border-left:$sidebar-nav-accent-size solid $sidebar-nav-accent-hover-color !important;       
    }

    &.router-link-active {
      border-left:$sidebar-nav-accent-size solid $sidebar-nav-accent-hover-color !important;
    }
  }

  .submenu:hover{    
    background-color: tint-color($green, 10%);
  }
}
