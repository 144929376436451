@import "@/assets/scss/custom"; @import "~bootstrap/scss/bootstrap";

#module_frame {
  border: none; 
  margin: 0px; 
  width: 100%; 
  height: calc(100vh - 130px); 
  overflow: hidden;
}

.card-sm {
  @include media-breakpoint-up(sm) {
    // apply card and shadow settings from sm up (@extend is not possible across media queries)
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: 0.2rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: 0;
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
  }
}
