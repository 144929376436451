@import "@/assets/scss/custom"; @import "~bootstrap/scss/bootstrap";

a#app_link {
  @include border-radius(var(--#{$prefix}nav-pills-border-radius));
  color: color-contrast($light);
  padding-left: var(--#{$prefix}nav-link-padding-x);

  &:hover {
      @include gradient-bg($primary);
      color: color-contrast($primary);
    }
}
