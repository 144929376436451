@import "@/assets/scss/custom"; @import "~bootstrap/scss/bootstrap";

div.app-page {
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;

  @include media-breakpoint-down(sm) {
    margin-bottom: 49px;
  }
}
