@import "@/assets/scss/custom"; @import "~bootstrap/scss/bootstrap";

a#dropdown_user {
  @include border-radius(var(--#{$prefix}nav-pills-border-radius));
  padding-left: var(--#{$prefix}nav-link-padding-x);
  color: color-contrast($light);
  
  &:hover {
      @include gradient-bg($primary);
      color: color-contrast($primary)
    }
}

.dropdown-toggle { outline: 0; }
